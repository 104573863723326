import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hidden md:flex items-center relative" }
const _hoisted_2 = ["placeholder", "title"]
const _hoisted_3 = { class: "cursor-pointer" }
const _hoisted_4 = { class: "md:hidden h-full w-full flex justify-end items-end absolute pr-2 bottom-2" }
const _hoisted_5 = { class: "flex gap-4 w-[35px] rounded-md focus-within:w-full focus-within:bg-hub-background-4 focus-within:dark:bg-hub-background-3 transition-all duration-200 justify-end relative items-center max-w-full" }
const _hoisted_6 = ["placeholder", "title"]
const _hoisted_7 = {
  key: 0,
  class: "absolute right-2 md:right-0 top-16 md:top-20 w-full max-w-80 bg-gray-lighter dark:bg-gray-darker rounded-b-md max-h-[500%] overflow-y-auto scrollbar"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flex gap-2 group-hover:bg-gray-light group-hover:dark:bg-gray p-2" }
const _hoisted_10 = {
  key: 0,
  class: "p-2"
}
const _hoisted_11 = { class: "flex gap-2 group-hover:bg-gray-light group-hover:dark:bg-gray p-2" }

import { useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';
	import { TSearchParameters, TSearchResult } from '@/model/model';
	import { PropType, ref, computed } from 'vue';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useRooms } from '@/store/store';
	import { filterAlphanumeric } from '@/core/extensions';
	import TruncatedText from '../elements/TruncatedText.vue';
	import { ISearchResults, SearchResult } from 'matrix-js-sdk';
	import Room from '@/model/rooms/Room';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SearchInput',
  props: {
		searchParameters: {
			type: Object as PropType<TSearchParameters>,
			required: true,
		},
		room: Room,
	},
  emits: [...usedEvents, 'scrollToEventId'],
  setup(__props, { emit: __emit }) {

	const pubhubs = usePubHubs();
	const rooms = useRooms();

	//Passed by the parentcomponent
	const props = __props;

	const searchResults = ref<TSearchResult[]>([]);
	const searched = ref(false);
	let searchResponse: ISearchResults | undefined = undefined;

	const emit = __emit;
	const { value, changed, cancel } = useFormInputEvents(emit);

	// searchresults shown in list. When the text 'more results' is shown the last result is omitted to keep it in view
	const searchResultsToShow = computed(() => {
		if (searchResults.value && searchResults.value.length > 0 && searchResponse && searchResponse.count && searchResponse.count > searchResults.value.length) {
			return searchResults.value.slice(0, -1); // Return all items except the last one
		}
		return searchResults.value; // Return all items
	});

	async function search() {
		searchResults.value = [];
		searched.value = true;
		if (!value.value) return;

		searchResponse = await pubhubs.searchRoomEvents(value.value as string, props.searchParameters);
		if (searchResponse && searchResponse.results.length > 0) {
			searchResults.value = mapSearchResult(searchResponse.results);
		}
	}

	async function loadMoreSearchResults() {
		if (searchResponse && searchResponse.next_batch) {
			while (searchResponse.next_batch) {
				searchResponse = await pubhubs.backPaginateRoomEventsSearch(searchResponse);
			}
			searchResults.value = mapSearchResult(searchResponse.results);
		}
		searched.value = true;
	}

	function reset() {
		searchResults.value = [];
		searched.value = false;
	}

	async function onScrollToEventId(eventId: string) {
		if (props.searchParameters.roomId && rooms.currentRoom!.roomId === props.searchParameters.roomId) {
			emit('scrollToEventId', { eventId: eventId });
			// reset();
		}
	}

	function mapSearchResult(results: SearchResult[]): TSearchResult[] {
		if (!results || results.length == 0) {
			return [];
		}
		let mappedResults = results.map(
			(result) =>
				({
					rank: result.rank,
					event_id: result.context.ourEvent.event.event_id!,
					event_type: result.context.ourEvent.event.type,
					event_body: result.context.ourEvent.event.content?.body,
					event_sender: result.context.ourEvent.event.sender,
				}) as TSearchResult,
		);
		mappedResults.forEach((element) => {
			element.event_body = formatSearchResult(element.event_body, value.value as string, 5);
		});
		return mappedResults;
	}

	function formatSearchResult(eventbody: string, searchterm: string, numberOfWords: number): string {
		if (!eventbody || !searchterm) return '';

		var words = filterAlphanumeric(eventbody)?.toLowerCase().split(' ');
		var searchWords = filterAlphanumeric(searchterm.trim())?.toLowerCase().split(' ');

		if (!words || !searchWords) return '';

		// Compare the words to the searchterm.
		// if searchterm is fully found, index will be > -1
		// if searchterm is not found, index will be -1
		var index = -1;
		for (var i = 0; i < words.length; i++) {
			if (words[i] === searchWords[0]) {
				index = i;
				for (var j = 1; j < searchWords.length; j++) {
					// If the words do not match, reset the index to -1 and break out of the loop
					if (words[i + j] !== searchWords[j]) {
						index = -1;
						break;
					}
				}
				// If the index is not -1 after the loop, the search term was found
				if (index !== -1) {
					break;
				}
			}
		}

		if (index === -1) {
			return '';
		}

		var start = Math.max(0, index - numberOfWords);
		var end = Math.min(words.length, index + searchWords.length + numberOfWords);

		return eventbody.split(' ').slice(start, end).join(' ');
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        class: "w-full min-w-48 md:pr-8 py-1 border-none rounded-md bg-gray-lighter placeholder:text-black dark:bg-gray-darker dark:text-white dark:placeholder:text-gray-light focus:border-black focus:outline-0 focus:outline-offset-0 focus:ring-0",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
        placeholder: _ctx.$t('others.search_room'),
        title: _ctx.$t('others.search_room'),
        onKeydown: [
          _cache[1] || (_cache[1] = ($event: any) => {
				_unref(changed)();
				reset();
			}),
          _cache[2] || (_cache[2] = _withKeys(($event: any) => (search()), ["enter"])),
          _cache[3] || (_cache[3] = _withKeys(($event: any) => {
				_unref(cancel)();
				reset();
			}, ["esc"]))
        ]
      }, null, 40, _hoisted_2), [
        [_vModelText, _unref(value)]
      ]),
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_Icon, {
          class: "-ml-6 search-icon dark:text-gray-light",
          type: "search",
          size: "sm",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (search()))
        })
      ])
    ])), [
      [_directive_click_outside, reset]
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          class: "h-10 flex-1 w-full placeholder:text-black dark:text-white dark:placeholder:text-gray-light bg-transparent border-none focus:outline-0 focus:outline-offset-0 focus:ring-0",
          type: "text",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
          placeholder: _ctx.$t('others.search_room'),
          title: _ctx.$t('others.search_room'),
          onKeydown: [
            _cache[6] || (_cache[6] = ($event: any) => {
					_unref(changed)();
					reset();
				}),
            _cache[7] || (_cache[7] = _withKeys(($event: any) => (search()), ["enter"])),
            _cache[8] || (_cache[8] = _withKeys(($event: any) => {
					_unref(cancel)();
					reset();
				}, ["esc"]))
          ]
        }, null, 40, _hoisted_6), [
          [_vModelText, _unref(value)]
        ]),
        _createElementVNode("button", {
          class: "px-1 rounded-md flex justify-center items-center cursor-pointer",
          onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (search()), ["stop"]))
        }, [
          _createVNode(_component_Icon, {
            class: "search-icon px-1",
            type: "search",
            size: "md"
          })
        ])
      ])
    ]),
    (searched.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (searchResults.value && searchResults.value.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(searchResultsToShow.value, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.event_id,
                  class: "group"
                }, [
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _withModifiers(($event: any) => (onScrollToEventId(item.event_id)), ["prevent"])
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_Avatar, {
                        userId: item.event_sender,
                        room: __props.room,
                        class: "flex-none h-6 w-6"
                      }, null, 8, ["userId", "room"]),
                      _createVNode(TruncatedText, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.event_body), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ], 8, _hoisted_8)
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_unref(value) !== '')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('others.search_nothing_found')), 1))
                  : _createCommentVNode("", true)
              ], 64)),
          (searchResults.value && searchResults.value.length > 0 && _unref(searchResponse) && _unref(searchResponse).count && _unref(searchResponse).count > searchResults.value.length)
            ? (_openBlock(), _createElementBlock("a", {
                key: 2,
                href: "#",
                onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (loadMoreSearchResults()), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('others.load_more_results')), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})